// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// jQuery Keywords declared
window.jQuery = $;
window.$ = $;

// Popper (Bootstrap dependency)
require("@popperjs/core")

// Bootstrap 5
import "bootstrap"
// Import the specific modules you may need (Modal, Alert, etc)
import { Tooltip, Popover } from "bootstrap"

// Application Stylesheet (scss)
import "../stylesheets/application";

// Import custom JS
import "./initialize";
import  "./form_toggle";
import "./sidebar";
import "./smooth_scroll";
import "./tour_navbar";
import "./toggle_private_tour";
import "./show_hide_hostel_select";

Rails.start()
Turbolinks.start()
ActiveStorage.start()
